import React from "react";
// import finalPageImage from "./images/final_page.png";
import finalPageImage from "./images/finalpageami.png"

const Chicago = () => {
  return (
    <div
      className="paper-background"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img
        src={finalPageImage}
        alt="Final Page"
        style={{ maxWidth: "100%", height: "auto" }}
      />
    </div>
  );
};

export default Chicago;
