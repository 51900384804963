import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import albertGrowingImage from "./images/albert_growing.webp";
import albertGrownImage from "./images/albert_grown.webp";
import albertJointImage from "./images/albert_joint.webp";
import albertYoungImage from "./images/albert_young.webp";
import bepiImage from "./images/bepi.webp";
import dueLaureeImage from "./images/due_lauree.webp";
import leoImage from "./images/leo.webp";
import leoneImage from "./images/leone.webp";
import amiguanciImage from "./images/amiguanci.webp";
import amimezzaImage from "./images/amimezza.webp";
import canatImage from "./images/canat.webp";
import pappaImage from "./images/pappagallino.webp";
import nasinoImage from "./images/nasino.webp";
import canexImage from "./images/canebello.webp";
import amisopresaImage from "./images/amisopresa.webp";
import bigbioImage from "./images/bigbio.webp";
import daniloImage from "./images/danilo.webp";
import amistancaImage from "./images/amistanca.webp";
import beargryllsImage from "./images/beargrylls.webp";
import falco from "./images/falco.webp";
import madre from "./images/madre.webp";
import hulk from "./images/fil_hulk.webp";
import padre from "./images/padre_gallo.webp";
import rosi from "./images/rosi.webp";
import stefi from "./images/stefi.webp";
import miki from "./images/miki.webp";
import edo_nudo from "./images/edo_nudo.webp";
import edo_wolverine from "./images/edo_wolverine.webp";
import marino from "./images/marino.webp";
import Chicago from "./Chicago";

const images = [
  albertGrowingImage,
  albertGrownImage,
  albertJointImage,
  albertYoungImage,
  bepiImage,
  dueLaureeImage,
  leoImage,
  leoneImage,
  amiguanciImage,
  amimezzaImage,
  canatImage,
  pappaImage,
  nasinoImage,
  canexImage,
  amisopresaImage,
  bigbioImage,
  daniloImage,
  amistancaImage,
  beargryllsImage,
  // falco,
  // madre,
  // hulk,
  // padre,
  // rosi,
  // stefi,
  // miki,
  // edo_nudo,
  // edo_wolverine,
  // marino,
];

const correctCombination = [
  amimezzaImage,
  amisopresaImage,
  amistancaImage,
  amiguanciImage,
];

function App() {
  const refs = useRef(images.map(() => React.createRef()));
  const [draggedImage, setDraggedImage] = useState(null);
  const [celebrate, setCelebrate] = useState(false);
  const [dropTarget, setDropTarget] = useState(null);
  const [filledBoxes, setFilledBoxes] = useState(Array(4).fill(false));
  const [boxContents, setBoxContents] = useState(Array(4).fill(null));

  useEffect(() => {
    refs.current.forEach((ref) => {
      const img = ref.current;
      if (img) {
        const initialX = Math.random() * (window.innerWidth - img.clientWidth);
        const initialY =
          Math.random() * (window.innerHeight - img.clientHeight);

        img.style.left = `${initialX}px`;
        img.style.top = `${initialY}px`;

        img.dataset.positionX = initialX;
        img.dataset.positionY = initialY;
        img.dataset.velocityX = 1 * (Math.random() < 0.5 ? 1 : -1); // random initial velocityX
        img.dataset.velocityY = 1 * (Math.random() < 0.5 ? 1 : -1); // random initial velocityY
        img.dataset.isMoving = "true";

        img.addEventListener("mousedown", () => {
          img.dataset.isMoving = "false";
        });

        img.addEventListener("mouseup", () => {
          img.dataset.isMoving = "true";
        });
      }
    });

    const updatePositions = () => {
      refs.current.forEach((ref) => {
        const img = ref.current;
        if (!img) return;

        if (img.dataset.isMoving === "true") {
          let positionX = parseFloat(img.dataset.positionX);
          let positionY = parseFloat(img.dataset.positionY);
          let velocityX = parseFloat(img.dataset.velocityX);
          let velocityY = parseFloat(img.dataset.velocityY);

          const imgWidth = img.clientWidth;
          const imgHeight = img.clientHeight;
          const maxX = window.innerWidth - imgWidth;
          const maxY = window.innerHeight - imgHeight;

          positionX += velocityX;
          positionY += velocityY;

          if (positionX <= 0 || positionX >= maxX) {
            velocityX = -velocityX * (Math.random() * 0.5 + 0.5); // reverse and vary velocityX
            positionX = Math.max(0, Math.min(positionX, maxX));
          }
          if (positionY <= 0 || positionY >= maxY) {
            velocityY = -velocityY * (Math.random() * 0.5 + 0.5); // reverse and vary velocityY
            positionY = Math.max(0, Math.min(positionY, maxY));
          }

          img.style.left = `${positionX}px`;
          img.style.top = `${positionY}px`;

          img.dataset.positionX = positionX;
          img.dataset.positionY = positionY;
          img.dataset.velocityX = velocityX;
          img.dataset.velocityY = velocityY;
        }
      });

      requestAnimationFrame(updatePositions);
    };

    requestAnimationFrame(updatePositions);
  }, []);

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData("imageIndex", index);
    setDraggedImage(index);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, boxIndex) => {
    e.preventDefault();
    const imageIndex = e.dataTransfer.getData("imageIndex");
    const img = refs.current[imageIndex].current;

    if (img) {
      const box = e.target.getBoundingClientRect();
      const boxCenterX = box.left + box.width / 2 - img.clientWidth / 2;
      const boxCenterY = box.top + box.height / 2 - img.clientHeight / 2;

      img.style.left = `${boxCenterX}px`;
      img.style.top = `${boxCenterY}px`;

      img.dataset.positionX = boxCenterX;
      img.dataset.positionY = boxCenterY;
    }

    const newFilledBoxes = [...filledBoxes];
    newFilledBoxes[boxIndex] = true;
    setFilledBoxes(newFilledBoxes);

    const newBoxContents = [...boxContents];
    newBoxContents[boxIndex] = imageIndex;
    setBoxContents(newBoxContents);

    setDraggedImage(null);
    setDropTarget(null);

    checkCombination(newBoxContents);
  };

  const handleDragEnter = (e, boxIndex) => {
    setDropTarget(boxIndex);
  };

  const handleDragLeave = (e) => {
    setDropTarget(null);
  };

  const flashBorders = (color) => {
    document.querySelectorAll(".box").forEach((box) => {
      box.classList.add(color);
      setTimeout(() => box.classList.remove(color), 100000);
    });
  };

  const resetImages = () => {
    refs.current.forEach((ref) => {
      const img = ref.current;
      if (img) {
        img.dataset.isMoving = "true";
        img.style.left = `${
          Math.random() * (window.innerWidth - img.clientWidth)
        }px`;
        img.style.top = `${
          Math.random() * (window.innerHeight - img.clientHeight)
        }px`;
        img.dataset.positionX = img.style.left;
        img.dataset.positionY = img.style.top;
      }
    });

    setFilledBoxes(Array(4).fill(false));
    setBoxContents(Array(4).fill(null));
  };

  const checkCombination = (boxContents) => {
    if (boxContents.every((content) => content !== null)) {
      const combination = boxContents.map((index) => images[index]);
      if (JSON.stringify(combination) === JSON.stringify(correctCombination)) {
        flashBorders("correct");
        setCelebrate(true);
      } else {
        flashBorders("incorrect");
        resetImages();
      }
    }
  };

  if (celebrate) {
    return <Chicago />;
  }

  return (
    <div className="paper-background">
      <div className="boxes-container">
        {[0, 1, 2, 3].map((boxIndex) => (
          <div
            key={boxIndex}
            className={`box ${dropTarget === boxIndex ? "highlight" : ""} ${
              filledBoxes[boxIndex] ? "filled" : ""
            }`}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, boxIndex)}
            onDragEnter={(e) => handleDragEnter(e, boxIndex)}
            onDragLeave={handleDragLeave}
          ></div>
        ))}
      </div>
      {images.map((src, index) => (
        <img
          key={index}
          ref={refs.current[index]}
          src={src}
          alt={`Flying image ${index}`}
          className="bouncing-image"
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
        />
      ))}
    </div>
  );
}

export default App;
